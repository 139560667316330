(function($) {
	'use strict'; 
	window.activitWP = {
		cache: {
			$document: $(document),
			$window: $(window)
		}, 
		init: function() {
			this.bindEvents();
		}, 
		bindEvents: function() { 
			var self = this;
			window.onbeforeunload = function () {
			  window.scrollTo(0, 0);
			}
			$(window).on('cookieAlertAccept',function(){
				console.log('cookies OK');
				window.dataLayer = window.dataLayer || [];
				  function gtag(){dataLayer.push(arguments);}
				  gtag('js', new Date());
				
				  gtag('config', 'UA-67715653-1');
			});
			$('.rejectcookies').on('click',function(e){
				$('.cookiealert').removeClass('show');
			});
			$(window).ready(function() {
				setTimeout(function(){
					$('#loader').fadeOut();
				}, 500);
				
				//$(window).scrollTop(0);
				
				if($('body').hasClass('isdesktop')){
				}
				self.initAnimations();
				self.initForms();
				if($('body').hasClass('page-template-template_home-php') ){
					self.initAnimatedHero();
				}
				$(window).resize(function() {
					self.handleResize();
				});
				
				$(document).scroll(function() {
				  if ($(this).scrollTop() > 150) { //Adjust 150
				    $('#main_header').addClass('compact');
				  } else {
				    $('#main_header').removeClass('compact');
				  }
				});
				
				
			});
		}, 
		handleResize: function() {
			var window_width = $(window).width();
		},
		/*
			
			elem.addClass('waypoint_entered');
				    elem.removeClass('waypoint_enter waypoint_exit waypoint_exited');
				    if(elem.attr('id')==='section_solutions_sage'){
					  	  $('.bloc_solution:first-child').removeClass('animate__fadeOutLeft').addClass('animate__fadeInLeft');
					  	  $('.bloc_solution:last-child').removeClass('animate__fadeOutRight').addClass('animate__fadeInRight');
				    }
				    if(elem.attr('id')==='section_temoignages'){
					    $('.bloc_temoignage').removeClass('animate__fadeOutDown').addClass('animate__fadeInUp');
					}
					if(elem.attr('id')==='section_actualites'){
					    setTimeout(function(){
					    	$('.colactu').removeClass('animate__fadeOutDown').addClass('animate__fadeInUp');
						},1000 );
					}
					
					*/
		initAnimations: function(){
			
			
			
			$('.full_section').each(function(){		
				var elem = $(this);		
				
				$('.bloc_solution_1').addClass('animate__fadeOutLeft');
				$('.bloc_solution_2').addClass('animate__fadeOut');
				$('.bloc_solution_3').addClass('animate__fadeOutRight');
				$('.bloc_temoignage').addClass('animate__fadeOutDown');
				$('.colactu').addClass('animate__fadeOutDown');
				$('.colbesoin').addClass('animate__fadeOut');
				$('.colpartenaire').addClass('animate__fadeOutDown');
				$('#poly_skills li .icon').addClass('animate__zoomOut');
				$('.iconbgpoly').addClass('animate__zoomOut');
				
				var waypoints = $(this).waypoint({
						offset: '50%',
						handler: function(direction) {
						var activearea = this.element.id;
						console.log(activearea + ' hit');
						if(activearea==='section_solutions_sage'){
							$('.bloc_solution_1').removeClass('animate__fadeOutLeft').addClass('animate__fadeInLeft');
							$('.bloc_solution_2').removeClass('animate__fadeOut').addClass('animate__fadeIn');
							$('.bloc_solution_3').removeClass('animate__fadeOutRight').addClass('animate__fadeInRight');
					    }
					    if(activearea==='section_temoignages'){
						    $('.bloc_temoignage').removeClass('animate__fadeOutDown').addClass('animate__fadeInUp');
						}
						if(activearea==='section_actualites'){
						    $('.colactu').removeClass('animate__fadeOutDown').addClass('animate__fadeInUp');
						}
						if(activearea==='apropos_expertise' || activearea==='archive_solutiongestion'||activearea==='formation_seformer'){
						    $('.colbesoin').removeClass('animate__fadeOut').addClass('animate__fadeIn');
						}
						if(activearea==='apropos_partenaires'){
							$('.colpartenaire').removeClass('animate__fadeOutDown').addClass('animate__fadeInUp');
						}
						if(activearea==='apropos_savoirfaire'){
							$('#poly_skills li .icon').removeClass('animate__zoomOut').addClass('animate__zoomIn');
						}
						if( (activearea==='single_solutiongestion') || (activearea==='single_familleproduit') ){
							$('.iconbgpoly').removeClass('animate__zoomOut').addClass('animate__zoomIn');
						}
					}
				});
			});
				
			const swiper_home_gestion = new Swiper('#home_swiper_gestion', {
				speed: 400,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
		          el: ".swiper-pagination",
		          clickable:true
		        },
		        loop:true,
				slidesPerView: 1,
				//spaceBetween: 30,
				centeredSlides: true,
				 breakpoints: {
		          640: {
		            slidesPerView: 1,
		            spaceBetween: 10,
		          },
		          
		          1199: {
		            slidesPerView: 3,
		            spaceBetween: 30,
		          },
		        },
        	});
        	$('#home_swiper_gestion .swiper-slide-active .slidecontent').on('click',function(e){
				var link = $(this).find('.lightcta').attr('href');
				location.href = link; 
			});
        	
        	
        	const swiper_block_formations = new Swiper('#swiper_formations', {
				speed: 400,
				navigation: {
					nextEl: '.swiperformations-button-next',
					prevEl: '.swiperformations-button-prev',
				},
				pagination: {
		          el: ".swiperformations-pagination",
		          clickable:true
		        },
				slidesPerView: 1,
				//spaceBetween: 30,
				centeredSlides: false,
/*
				 breakpoints: {
		          640: {
		            slidesPerView: 1,
		            spaceBetween: 10,
		          },
		          
		          1199: {
		            slidesPerView: 3,
		            spaceBetween: 30,
		          },
		        },
*/
        	});


		
		},
		initForms: function(){
			
			$('#filtre_formations').on('change',function(e){
				console.log("form");
				
				$("#checkbox_all").click(function(){
				    $('input:checkbox').not(this).prop('checked', this.checked);
				});
				
				var form = $('.filtres:checked').serialize();
				console.log(form);
				$.ajax( {
					type: "POST",
					url: '/wp-content/themes/activit2021/inc/get_formations.php',
					data: form,
					success: function( response ) {
						$('#row_formations').html( response );
						console.log( response );
					},
					error: function( response ) {
						//$('#status').text('Erreur pour poster le formulaire : '+ response.status + " " + response.statusText);
						console.log( response );
					}						
				} );
			});
			
			$('.wrapper_solution').on('click',function(e){
				var link = $(this).find('.lightcta').attr('href');
				location.href = link; 
			});
			//sets timer variable
		  var timer;
		  
		  // when the button and button menu are hovered
/*
		  $('.dropdown-toggle').hover(function() {
		    $('.dropdown').dropdown('hide');

		    $(this).dropdown('toggle');
		    
		  }, function() {
 
		  });
*/
			
			$('.mailchimpSignupForm').each(function() { 
				var theform = $(this);
				theform.validate({
					submitHandler: function(form, event) {
						event.preventDefault();
						var formdata 		= theform.serialize();
			
						jQuery.ajax({
				            url: '/wp-content/themes/activit2021/inc/mailchimp_subscribe.php',
				            type: 'post',
				            data: formdata,
				            success: function(response) {
				            	theform.slideUp();
			                	theform.next('.mailchimpSignupResponse').html(response);								
			                }
						});           
			        }
			    });
			});
			if($('body').hasClass('single-formation')){
				$('form.wpcf7-form').each(function() { 
					var contactform = $(this);
	
					contactform.validate({
						rules: {
							champ_nom: "required",
							champ_prenom:"required",
							champ_societe:"required",
							champ_tel:"required",
							champ_nbstagiaires:"required",
							champ_codepostal:"required",
							champ_email: {
								required: true,
								email: true
								},
						},
						submitHandler: function(elform) {
							if(elform.valid()){
								console.log('formulaire valide');
								elform.submit();
							} else {
								console.log('formulaire invalide');
								//return false;
							}
						}

					});	
				});
			} else if($('body').hasClass('page-id-1801')){
				$('form.wpcf7-form').each(function() { 
					var contactform = $(this);
	
					contactform.validate({
						rules: {
							champ_nom: "required",
							champ_prenom:"required",
							champ_tel:"required",
							champ_email: {
								required: true,
								email: true
							},
							champ_curriculum:{
								required:true,
								extension: "pdf"
							}
						},
						submitHandler: function(elform) {
							if(elform.valid()){
								console.log('formulaire valide');
								elform.submit();
							} else {
								console.log('formulaire invalide');
								//return false;
							}
						}

					});	
				});
			} else {
				$('form.wpcf7-form').each(function() { 
					var contactform = $(this);
	
					contactform.validate({
						rules: {
							champ_nom: "required",
							champ_prenom:"required",
							champ_societe:"required",
							champ_tel:"required",
							champ_email: {
								required: true,
								email: true
							}
						},
						submitHandler: function(elform) {
							elform.submit();
/*
							if(elform.valid()){
								console.log('formulaire valide');
								elform.submit();
							} else {
								console.log('formulaire invalide');
								//return false;
							}
*/
						}

					});
				});
			}




		},
		initAnimatedHero:function(){
			VANTA.WAVES({
			  el: "#home_hero",
			  mouseControls: true,
			  touchControls: true,
			  gyroControls: false,
			  minHeight: 200.00,
			  minWidth: 200.00,
			  scale: 1.00,
			  scaleMobile: 1.00,
			  color: '#3e444e',
			  shininess: 5.00,
			  waveHeight: 7.50,
			  waveSpeed: 0.80,
			  zoom: 0.86
			});
		},
	}
	activitWP.init();
})(jQuery);